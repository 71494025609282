let menuList = {
  SW: [
    {
      id: 1000,
      title: '首页',
      icon: 'el-icon-home',
      path: '/factory/index',
      permission: 'home:SWIndex'
    },
    {
      id: 3000,
      title: '固废管理',
      icon: 'el-icon-yuanliaocangfengbao',
      path: '/factory/waste',
      permission: "waste",
      children: [
        {
          id: 3100,
          title: '投料记录',
          path: '/factory/waste/feeding',
          permission: "waste:deliveryDetail"
        },
        {
          id: 3150,
          title: '暂存单',
          name: 'waste/storage',
          path: '/factory/waste/storage',
          permission: "waste:storageDetail"
        },
        {
          id: 3160,
          title: '入库单',
          name: 'waste/orderIn',
          path: '/factory/waste/orderIn',
          permission: "waste:orderIn"
        },
        {
          id: 3170,
          title: '出库单',
          name: 'waste/orderOut',
          path: '/factory/waste/orderOut',
          permission: "waste:orderOut"
        },
        {
          id: 3180,
          title: '仓库管理',
          name: 'waste/bration',
          path: '/factory/waste/bration',
          permission: "waste:facilityCorrect"
        },
        // {
        //   id: 3190,
        //   title: '工单',
        //   name: 'waste/workOrder',
        //   path: '/factory/waste/workOrder',
        //   permission: "waste:mapNumMaterial"
        // },
        {
          id: 3200,
          title: '投料码',
          name: 'waste/deliveryCode',
          path: '/factory/waste/deliveryCode',
          permission: "waste:deliveryCode"
        },
        {
          id: 3220,
          title: '销毁证明',
          name: 'waste/destroyCert',
          path: '/factory/waste/destroyProve',
          permission: "waste:destroyProve"
        },
        {
          id: 3230,
          title: '产废项目',
          name: 'waste/project',
          path: '/factory/waste/project',
          permission: "waste:deliveryExtend"
        }
      ]
    },
    {
      id: 3300,
      title: '结账管理',
      icon: 'el-icon-message',
      path: '/factory/settle',
      permission: "settle",
      children: [
        {
          id: 3310,
          title: '待结账',
          path: '/factory/settle/wait',
          permission: "settle:wait"
        },
        {
          id: 3320,
          title: '结账单',
          path: '/factory/settle/complete',
          permission: "settle:complete"
        }
      ]
    },
    {
      id: 3900,
      title: '数据分析',
      icon: 'el-icon-message',
      path: '/factory/analysis',
      permission: "waste:summary",
      children: [
        {
          id: 3940,
          title: '库存统计',
          path: '/factory/analysis/stockStat',
          permission: "waste:summary:stockStat"
        },
        {
          id: 3920,
          title: '累计产量',
          path: '/factory/analysis/output',
          permission: "waste:summary:output"
        },
        {
          id: 3950,
          title: '数据大厅',
          path: '/factory/analysis/dashboard',
          permission: "waste:summary:dashboard"
        }
      ]
    },
    {
      id: 4000,
      title: '环保台账',
      icon: 'el-icon-yuanliaocangfengbao',
      path: '/factory/ledger',
      permission: 'ledger',
      children: [
        {
          id: 4100,
          title: '废物产生清单',
          path: '/factory/ledger/produceList',
          permission: "ledger:produceList"
        },
        {
          id: 4200,
          title: '废物流向表',
          path: '/factory/ledger/flowDirection',
          permission: 'ledger:flowDirection'
        },
        {
          id: 4300,
          title: '废物出厂环节表',
          path: '/factory/ledger/factoryLink',
          permission: 'ledger:factoryLink'
        },
        {
          id: 4400,
          title: '废物产生环节表',
          path: '/factory/ledger/produceLink',
          permission: 'ledger:produceLink'
        }
      ]
    },
    {
      id: 5000,
      title: '系统设置',
      icon: 'el-icon-peizhi',
      path: '/factory/setting',
      permission: 'setting',
      children: [
        {
          id: 5100,
          title: '用户管理',
          path: '/factory/setting/user',
          permission: 'setting:sysUser'
        },
        {
          id: 5200,
          title: '物料号关联',
          path: '/factory/setting/psa',
          permission: 'setting:psaMap'
        },
        {
          id: 5300,
          title: '投料卡关联',
          path: '/factory/setting/linked',
          permission: 'setting:psaCardMap'
        },
        {
          id: 5400,
          title: '材料关联',
          path: '/factory/setting/material',
          permission: 'setting:mapMaterialWaste'
        },
      ]
    }
  ],
  HW: [
    {
      id: 1000,
      title: '首页',
      icon: 'el-icon-shouye',
      path: '/hazardous/index',
      permission: 'home:HWIndex'
    },
    {
      id: 500050,
      title: '危废管理',
      icon: 'el-icon-yuanliaocangfengbao',
      path: '/hazardous/waste',
      permission: 'hw',
      children: [
        {
          id: 3000,
          title: '危废产生台账',
          icon: 'el-icon-kehuguanli',
          path: '/hazardous/waste/delivery',
          permission: 'hw:delivery'
        },
        {
          id: 3900,
          title: '危废入库台账',
          icon: 'el-icon-fanganguanli',
          path: '/hazardous/waste/storageIn',
          permission: 'hw:storageIn'
        },
        {
          id: 4000,
          title: '危废出库台账',
          icon: 'el-icon-fuwuguanli',
          path: '/hazardous/waste/storageOut',
          permission: 'hw:storageOut'
        },
        {
          id: 500400,
          title: '自行利用处置台账',
          icon: 'el-icon-fuwuguanli',
          path: '/hazardous/waste/dispose',
          permission: 'hw:storageUse'
        },
        {
          id: 500050,
          title: '管理计划',
          icon: 'el-icon-fuwuguanli',
          path: '/hazardous/waste/plan',
          permission: 'hw:plan'
        }
      ]
    },
    {
      id: 3300,
      title: '结账管理',
      icon: 'el-icon-message',
      path: '/hazardous/settle',
      permission: "hw:settle",
      children: [
        {
          id: 3310,
          title: '待结账',
          path: '/hazardous/settle/wait',
          permission: "hw:settle:wait"
        },
        {
          id: 3320,
          title: '结账单',
          path: '/hazardous/settle/complete',
          permission: "hw:settle:complete"
        }
      ]
    },
    {
      id: 507000,
      title: '数据分析',
      icon: 'el-icon-message',
      path: '/hazardous/analysis',
      permission: "hw:summary",
      children: [
        {
          id: 507100,
          title: '月报',
          icon: 'el-icon-kehuguanli',
          path: '/hazardous/analysis/monthReport',
          permission: 'hw:summary:monthReport'
        },
        {
          id: 507200,
          title: '季报',
          icon: 'el-icon-kehuguanli',
          path: '/hazardous/analysis/quarterReport',
          permission: 'hw:summary:quarterReport'
        },
        {
          id: 507300,
          title: '年报',
          icon: 'el-icon-kehuguanli',
          path: '/hazardous/analysis/yearReport',
          permission: 'hw:summary:yearReport'
        }
      ]
    },
    {
      id: 508000,
      title: '系统设置',
      icon: 'el-icon-peizhi',
      path: '/factory/setting',
      permission: 'setting',
      children: [
        {
          id: 500500,
          title: '生产设施配置',
          icon: 'el-icon-fuwuguanli',
          path: '/hazardous/setting/facility',
          permission: 'setting:deliveryFacility'
        },
        {
          id: 508200,
          title: '利用处置设施配置',
          icon: 'el-icon-fuwuguanli',
          path: '/hazardous/setting/useFacility',
          permission: 'setting:useFacility'
        },
        {
          id: 508300,
          title: '贮存设施配置',
          icon: 'el-icon-fuwuguanli',
          path: '/hazardous/setting/storageFacility',
          permission: 'setting:storageFacility'
        },
        {
          id: 508400,
          title: '接收单位配置',
          icon: 'el-icon-fuwuguanli',
          path: '/hazardous/setting/transfer',
          permission: 'setting:transfer'
        }
      ]
    }
  ]
}




export default menuList;